import React from 'react';
import {BrowserRouter as Router, Route, Redirect, Switch} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import './styles.css'
import '@fortawesome/fontawesome-free/css/all.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple, faGooglePlay } from '@fortawesome/free-brands-svg-icons';
import queryString from "query-string";
import Features from "./components/features";
import Details from "./components/details";
import About from "./components/about";
import Support from "./components/support";
import ComplaintContainer from "./components/dmca/complaint_container";
import AtIcon from './img/at-icon.png';

const getAttrs = (iframeTag) => {

    var doc = document.createElement('div');
    doc.innerHTML = iframeTag;

    const iframe = doc.getElementsByTagName('iframe')[0];
    return [].slice
        .call(iframe.attributes)
        .reduce((attrs, element) => {
            attrs[element.name] = element.value;
            return attrs;
        }, {});
};

function isEmpty(s) {
    return (!s || s.length === 0 || !s.trim());
}

const iframe = function (id) {
    return `<iframe src="https://www.youtube.com/embed/${id}" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>`
};

class Player extends React.Component {
    constructor(props) {
        super(props);
        this.urlId = props.match.params.urlId
    }

    componentDidMount() {
        const backgroundImageUrl = `https://i.ytimg.com/vi/${this.urlId}/maxresdefault.jpg`;

        const backgroundDivElem = document.querySelector('.background-div');
        if (backgroundDivElem) {
            backgroundDivElem.style.backgroundImage = `url(${backgroundImageUrl})`;
        }

        if (backgroundDivElem) {
            backgroundDivElem.style.backgroundImage = `url(${backgroundImageUrl})`;
        }
    }

    render() {
        return (
            <div className="video-container">
                <iframe {...getAttrs(iframe(this.urlId))} title="embed_player"/>
            </div>
        )
    }
}

const VIDEO_IDS = ['znN81b1Z0Eg', 'PeTd2dDLt_c', 'FLdIoqnKSh0', 'PY4SnJxNwHQ', '3JTIZ4X3UZk', 'JGoOZDTDD-0'];

class PlayerL extends React.Component {
    constructor(props) {
        super(props);
        this.videoId = VIDEO_IDS[Math.floor(Math.random() * VIDEO_IDS.length)];
    }

    componentDidMount() {
        const backgroundImageUrl = `https://i.ytimg.com/vi/${this.videoId}/maxresdefault.jpg`;

        const backgroundDivElem = document.querySelector('.background-div');
        if (backgroundDivElem) {
            backgroundDivElem.style.backgroundImage = `url(${backgroundImageUrl})`;
        }

        if (backgroundDivElem) {
            backgroundDivElem.style.backgroundImage = `url(${backgroundImageUrl})`;
        }
    }

    render() {
        return (
            <div className="video-container">
                <iframe src={`https://www.youtube-nocookie.com/embed/${this.videoId}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
        );
    }
}

class YTRouter extends React.Component {
    render() {
        return (
            <Router>
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 offset-1 video-col">
                            <Route exact path="/" component={PlayerL}/>
                            <Route path="/play/yt/:urlId" component={Player}/>
                            <Route path="/e/play/yt/:urlId" component={Player}/>
                            <Route path="/play/jmd/" component={Music}/>
                            <Route path="/play/podcast/" component={Music}/>
                            <Route path="/play/au/" component={Music}/>
                            <Route path="/play/sp/" component={Music}/>
                            <Route path="/play/hts/" component={Music}/>
                        </div>
                    </div>
                </div>
            </Router>
        )
    }
}

class AppRouter extends React.Component {
    render() {
        return (
            <Router>
                <Switch>
                    <Route path="/latest" component={() => {
                        window.location.href = "https://play.google.com/store/apps/details?id=com.atpc";
                        return null;
                    }}/>
                    <Route path="/copyright_complaint_form/:appId" component={ComplaintContainer}/>
                    <Route path="/copyright_complaint_form" component={ComplaintContainer}/>
                    <Route path="/support-music-player-downloader" component={() => {
                        window.location.href = "https://www.facebook.com/free.music.downloader.player";
                        return null;
                    }}/>
                    <Route path="/support-online-music-video-player" component={() => {
                        window.location.href = "https://www.facebook.com/free.music.player.youtube";
                        return null;
                    }}/>
                    <Route path="/support-epic" component={() => {
                        window.location.href = "https://www.facebook.com/free.music.player.youtube";
                        return null;
                    }}/>
                    <Route path="/ios-support" component={() => {
                        window.location.href = "https://www.facebook.com/free.music.downloader.player";
                        return null;
                    }}/>
                    <Route path="/nova-ranks" component={() => {
                        window.location.href = "https://www.appannie.com/apps/ios/app/music-downloader-player-2/app-ranking/?device=iphone&type=best-ranks";
                        return null;
                    }}/>
                    <Route path="/copyright" component={() => {
                        window.location.href = "https://www.atplayer.com/terms-of-use.html#dmca-copyright-policy";
                        return null;
                    }}/>
                    <Route path="/magic" component={() => {
                        window.location.href = "https://www.youtube.com/watch?v=8TsT13BQkfI";
                        return null;
                    }}/>
                    <Route path="/faq" component={() => {
                        window.location.href = "https://www.atplayer.com/faq.html";
                        return null;
                    }}/>
                    <Route path="/discord" component={() => {
                        window.location.href = "https://discord.gg/eX4hsMWMD2";
                        return null;
                    }}/>
                    <Route path="/recover" component={() => {
                        window.location.href = "https://www.atplayer.com/faq.html";
                        return null;
                    }}/>
                    <Route path="/download" component={() => {
                        window.location.href = "https://www.youtube.com/watch?v=3JTIZ4X3UZk";
                        return null;
                    }}/>
                    <Route path="/pip" component={() => {
                        window.location.href = "https://www.youtube.com/shorts/uDk8QBaXwTw";
                        return null;
                    }}/>
                    <Route path="/dl" component={() => {
                        window.location.href = "https://www.youtube.com/watch?v=3JTIZ4X3UZk";
                        return null;
                    }}/>
                    <Route path="/d" component={() => {
                        window.location.href = "https://www.youtube.com/watch?v=Y30h1IENtvI";
                        return null;
                    }}/>
                    <Route path="/at" component={() => {
                        window.location.href = "https://play.google.com/store/apps/details?id=com.atpc";
                        return null;
                    }}/>
                    <Route path="/app" component={() => {
                        window.location.href = "https://play.google.com/store/apps/details?id=com.atpc";
                        return null;
                    }}/>
                    <Route path="/moon" component={() => {
                        window.location.href = "https://play.google.com/store/apps/details?id=io.freemusic";
                        return null;
                    }}/>
                    <Route path="/vega" component={() => {
                        window.location.href = "https://play.google.com/store/apps/details?id=atmusic.app";
                        return null;
                    }}/>
                    <Route path="/echo" component={() => {
                        window.location.href = "https://play.google.com/store/apps/details?id=atradio.app";
                        return null;
                    }}/>
                    <Route path="/youtube" component={() => {
                        window.location.href = "https://www.youtube.com/c/atplayer";
                        return null;
                    }}/>
                    <Route path="/ios" component={() => {
                        window.location.href = "https://apps.apple.com/app/id1447312762";
                        return null;
                    }}/>
                    <Route path="/nova" component={() => {
                        window.location.href = "https://apps.apple.com/app/id1447312762";
                        return null;
                    }}/>
                    <Route path="/epic" component={() => {
                        window.location.href = "https://apps.apple.com/app/id1481685496";
                        return null;
                    }}/>
                    <Route path="/epic-support" component={() => {
                        window.location.href = "https://www.facebook.com/free.music.player.youtube";
                        return null;
                    }}/>
                    <Route path="/leto" component={() => {
                        window.location.href = "https://apps.apple.com/app/id1451350120";
                        return null;
                    }}/>
                    <Route path="/blue" component={() => {
                        window.location.href = "https://play.google.com/store/apps/details?id=freemusic.player";
                        return null;
                    }}/>
                    <Route path="/contact" component={() => {
                        window.location.href = "/contact.html";
                        return null;
                    }}/>
                    <Route path="/support" component={() => {
                        window.location.href = "/contact.html";
                        return null;
                    }}/>
                    <Route path="/terms" component={() => {
                        window.location.href = "/terms-of-use.html";
                        return null;
                    }}/>
                    <Route path="/privacy" component={() => {
                        window.location.href = "/privacy-policy.html";
                        return null;
                    }}/>
                    <Route path="/" component={Landing}/>
                </Switch>
            </Router>
        )
    }
}

class ChooseApp extends React.Component {
    render() {
        return (
            <div className="container-fluid choose-app">
                <div className="section-heading inverse">
                    <h1><img src={AtIcon} alt="Logo" className="custom-icon"/> AT PLAYER</h1>
                    <div className="spacer"></div>
                    <h2 className="gradient-header">Music app chosen by 200M+ users: 300M+ songs for free!</h2>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="platforms inverse">
                            <a href="https://play.google.com/store/apps/details?id=com.atpc" target="_blank"
                               className="btn btn-primary inverse custom-flex-btn">
                                <div className="icon-wrapper">
                                    <FontAwesomeIcon icon={faGooglePlay} className="icon-googleplay"/>
                                </div>
                                <div className="text-wrapper">
                                    <div>Get it on</div>
                                    <div><b>Google Play</b></div>
                                </div>
                            </a>
                            <a href="https://apps.apple.com/app/id1447312762" target="_blank"
                               className="btn btn-primary inverse custom-flex-btn">
                                <div className="icon-wrapper">
                                    <FontAwesomeIcon icon={faApple} className="icon-appstore"/>
                                </div>
                                <div className="text-wrapper">
                                    <div>Download on the</div>
                                    <div><b>App Store</b></div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

class Landing extends React.Component {
    render() {
        return (
            <div className="container-fluid top-div">
                <div className="background-div"></div>
                <ChooseApp/>
                <YTRouter/>
                <About/>
                <Features/>
                <Details/>
                <Support/>
            </div>
        )
    }
}

class Main extends React.Component {
    render() {
        return (
            <AppRouter/>
        )
    }
}

class Music extends React.Component {
    constructor(props) {
        super(props);
        let query = queryString.parseUrl(props.location.search).query;
        let trackToken = query.track;
        if (trackToken) {
            let decoded = "";
            let shareToken = {};
            try {
                decoded = window.atob(trackToken);
                shareToken = JSON.parse(decoded);
            } catch (e) {
            }

            this.title = shareToken.tit;
            this.name = shareToken.nm;

            if (isEmpty(this.name) && isEmpty(this.title)) {
                this.displayName = "Unknown";
            } else if (isEmpty(this.name)) {
                this.displayName = this.title;
            } else if (isEmpty(this.title)) {
                return this.displayName = this.name;
            } else {
                this.displayName = `${this.name} - ${this.title}`;
            }

            if (shareToken.tp === 'jmd' && shareToken.uid) {
                this.streamUrl = "https://mp3l.jamendo.com/?trackid=" + shareToken.uid + "&format=mp32&u=0";
            } else if (shareToken.tp === 'au' && shareToken.uid) {
                this.streamUrl = "https://discoveryprovider.audius.co/v1/tracks/" + shareToken.uid + "/stream?app_name=nova";
            } else if (shareToken.tp === 'sp' && shareToken.uid) {
                this.streamUrl = "https://api.spreaker.com/v2/episodes/" + shareToken.uid + "/play.mp3";
            } else if (shareToken.url) {
                this.streamUrl = shareToken.url
            }
        }

    }


    render() {
        if (!this.streamUrl) {
            //if there was an invalid token or json or any other error and streamUrl is not present
            // then simply redirect to main page and show default playlist
            return <Redirect to='/'/>;
        }
        return (
            <div className="container-fluid section-heading inverse">
                <div className="spacer"></div>
                <p>{this.displayName}</p>
                <audio className="audio" src={this.streamUrl} controlsList="nodownload" controls/>
            </div>
        );
    }
}

export default Main